<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    
      <v-layout>
        <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
         <!--  <pre>{{ detail }}</pre> -->
          <template v-if="detail.project_value > 0 & detail.status < 4">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button custom-btn-color white--text"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="createMilestone"
                >
                  <v-icon>mdi-plus</v-icon> create
                </v-btn>
              </template>
              <span>Create New Milestone</span>
            </v-tooltip>
          </template>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllMilestones"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>Reload</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    <ListingTable
      :columnCount="4"
      :dataLoading="dataLoading"
      :rowData="milestoneList"
    >
      <template v-slot:thead>
        <thead style="position: sticky;
            top: 0;
            z-index: 99;">
          <tr>
            <th class="simple-table-th" width="10%">Milestone #</th>
            <th class="simple-table-th" width="15%">Name</th>
            <th class="simple-table-th" width="15%">Description</th>
            <th class="simple-table-th" width="15%">Dates</th>
            <th class="simple-table-th"  width="20%">Milestones</th>
            <th class="simple-table-th"  width="15%">Invoice</th>
            <th class="simple-table-th"  width="20%">Status</th>
            <th class="simple-table-th" width="10%">Action</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="milestoneList.length > 0">
            <tr :key="index" v-for="(data, index) in milestoneList">
              <td class="simple-table-td" width="10%">
                <Barcode
                  :barcode="data.barcode"
                ></Barcode>
              </td>
              <td class="simple-table-td" width="15%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.name">
                    {{ data.name }}
                  </template>
                </p> 
              </td>
              <td class="simple-table-td" width="15%" style="max-width: 500px;">
                <p class="m-0 custom-nowrap-ellipsis" style="white-space: pre-wrap !important;">
                  <template v-if="data && data.description">
                    {{ data.description }}
                  </template>
                  <template v-else
                      ><em class="text--secondary"
                        >No description</em
                      ></template
                    >
                </p> 
              </td>
              <td class="simple-table-td" width="15%">
                <div class="project-listing-dates">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Date: </b>
                    <template v-if="data && data.date">
                        {{ formatDate(data.date) }}
                      </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reminder: </b>
                    <template v-if="data.reminder_date">{{
                      formatDate(data.reminder_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >Not Reminder</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td"  width="20%">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Milestone Amount: </b>
                    <template v-if="data && data.amount">
                        {{ formatMoney(data.amount) }}
                      </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Milestone Percentage: </b>
                    <template v-if="data && data.progress">
                        {{ data.progress }} %
                      </template>
                  </p>
              </td>
              <td class="simple-table-td"  width="15%" >
                <p class="m-0 custom-nowrap-ellipsis" v-if="data && !data.invoice">
                  <v-tooltip top content-class="custom-top-tooltip" z-index="99">
                          <template v-slot:activator="{ on, attrs }" >
                            <v-btn 
                              depressed
                              fab
                              dark
                              x-small
                              :color="data.status == 1 ? '#c5c5c5':'cyan'"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border-radius: 24px !important;
                              "
                              v-on:click.stop.prevent="data.status != 1 ? invoiceDialog(data,data.id) : 'javascript:void(0)'"
                              class="mr-3 custom-disabled"
                            >
                              <v-icon>mdi-receipt</v-icon>
                            </v-btn>
                          </template>
                          <span>Create Invoice</span>
                    </v-tooltip>
                 <!--  <v-icon    size="30" v-on:click.stop.prevent="invoiceDialog(data.id)">mdi-file-document-outline</v-icon> -->
                </p>
                  <div class="d-flex" v-if="data && data.invoice">
                    <p class="m-0 custom-nowrap-ellipsis" v-if="data && data.invoice" v-on:click.stop.prevent="invoiceViewDialog(data.invoice.id)">
                    <b>Date: </b>
                    <template v-if="data && data.invoice && data.invoice.invoice_date">
                        {{ formatDate(data.invoice.invoice_date) }}
                      </template>
                  </p>
                   <v-spacer></v-spacer>
                    <v-icon v-if="data.invoice.payment_status != 'complate' && data.status != 1" v-on:click="invoiceUpdateDialog(data,data.id,data.invoice.id)" color="green"  size="25" >mdi-pencil</v-icon>
                  </div>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="data && data.invoice && data.invoice.invoice_amount" v-on:click.stop.prevent="invoiceViewDialog(data.invoice.id)">
                    <b>Invoice: </b>
                    <template v-if="data && data.invoice && data.invoice.invoice_amount">
                        {{ formatMoney(data.invoice.invoice_amount) }}
                      </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="data && data.invoice && data.invoice.collected_amount" v-on:click.stop.prevent="invoiceViewDialog(data.invoice.id)">
                    <b>Collected: </b>
                    <template v-if="data && data.invoice && data.invoice.collected_amount">
                        {{ formatMoney( data.invoice.collected_amount) }}
                      </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis"  v-if="data && data.invoice && data.invoice.payment_status" v-on:click.stop.prevent="invoiceViewDialog(data.invoice.id)">
                    <b>Payment Status: </b>
                    <template  v-if="data && data.invoice && data.invoice.payment_status">
                          <v-chip label small :color="data.invoice.payment_status == 'complate' ? 'green':'red'" text-color="" outlined >
                            <template v-if="data.invoice.payment_status == 'complate'">
                                 Complete
                                </template>
                                <template v-else>
                                 Pending
                                </template>
                          </v-chip>
                      </template>
                  </p>
              </td>
              <td class="simple-table-td"  width="20%">
                <div
                        v-on:click.stop
                        class="d-flex justify-space-between align-center mb-1"
                      >
                        <v-menu
                          offset-y
                          min-width="100px"
                          style="padding: 0px 4px"
                          rounded
                          :disabled="data.status == 1"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex justify-space-between align-center"
                              style="
                                padding: 0px 6px;
                                background-color: #f7faff;
                                width: 110px;
                                border-radius: 9px;
                              "
                              :style="`border:1px solid ${getColor(data.status)}`"
                            >
                              <span
                                :class="`text-capitalize font-level-1 ${getTextColor(
                                  data.status
                                )}`"
                                >
                                <template v-if="data.status == 1">
                                 Complete
                                </template>
                                <template v-else>
                                 Pending
                                </template>
                                </span
                              >
                              <v-icon
                                style="font-size: 22px"
                                :color="data.color"
                                small
                                >mdi-menu-down</v-icon
                              >
                            </div>
                          </template>
                          <v-list elevation>
                            <v-list-item
                              v-on:change="
                                openConfirm(item.value, data,)
                              "
                              class="list-item cursor-pointer p-1"
                              v-for="(item, index) in statusUpdateList"
                              :key="index"
                            >
                              <v-list-item-title class="pl-1">
                                <v-chip
                                  style="height: 15px; width: 15px; padding: 0"
                                  :color="item.color"
                                >
                                </v-chip>
                                {{ item.text }}</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip" z-index="99">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="data.status != 1"
                           
                              depressed
                              fab
                              dark
                              x-small
                              color="green"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border-radius: 24px !important;
                              "
                              v-on:click="getSingle(data.uuid)"
                              class="mr-3 custom-disabled"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                    </v-tooltip>
                     <v-tooltip top content-class="custom-top-tooltip" z-index="99">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              v-if="data.status != 1"
                              fab
                           
                              dark
                              x-small
                              color="red"
                              class="mr-3 custom-disabled-del"
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border-radius: 24px !important;
                              "
                              v-on:click="deleteData(data)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                      </v-tooltip>
                      <v-icon  color="cyan"  size="25" v-on:click="attchments">mdi-paperclip</v-icon>
                </p> 
              </td>
              
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no milestone at the moment.
              </p>
            </td>
          </tr>
        </tbody>
        <Dialog :commonDialog="milestoneDialog" :dialogWidth="900">
          <template v-slot:title><template v-if="milestoneCreate.uuid">Update</template> <template v-else>Create</template> Milestones</template>
          <template v-slot:body>
            <v-container fluid class="pt-0" v-if="milestoneDialog">
              <v-form
                ref="milestoneForm"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="createOrUpdate"
              >
                <v-row>
                  <v-col md="12" class="pt-0">
                    <table class="width-100">
                      <tr>
                        <td colspan = "2" class="py-2">
                          <label class="font-weight-500 font-size-16 required"
                            > Name</label
                          > 
                          <v-text-field
                            v-model.trim="milestoneCreate.name"
                            dense
                            filled
                            color="cyan"
                            label=" Name"
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            solo
                            flat
                            :rules="[
                              validateRules.required(
                                milestoneCreate.name,
                                ' Name'
                              ),
                              validateRules.required(
                                milestoneCreate.name,
                                ' Name'
                              ),
                              validateRules.minLength(
                                milestoneCreate.name,
                                ' Name',
                                1
                              ),
                              validateRules.maxLength(
                                milestoneCreate.name,
                                ' Name',
                                100
                              ),
                            ]"
                          ></v-text-field>
                        </td>
                      </tr>

                      <tr>
                        <td colspan = "2" class="py-2">
                          <label class="font-weight-500 font-size-16"
                            > Description</label
                          > 
                          <v-textarea
                            v-model.trim="milestoneCreate.description"
                            auto-grow
                            dense
                            filled
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            color="cyan"
                            label="Description"
                            solo
                            flat
                            :rules="[
                              validateRules.minLength(
                                milestoneCreate.description,
                                'Description',
                                1
                              ),
                              validateRules.maxLength(
                                milestoneCreate.description,
                                'Description',
                                100
                              ),
                            ]"
                             v-on:keypress="(e) => manageLimit(e)"
                             v-on:paste="(e) => onPaste(e)"
                            row-height="15"
                          ></v-textarea>
                          <div class="text-right"
                            >{{
                              milestoneCreate.description
                                ? milestoneCreate.description.length
                                : 0
                            }}/100</div
                          >
                        </td>
                      </tr>
                      


                      <tr>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >Date</label
                          >
                          <DatePicker
                            :dataLoading="dataLoading"
                            solo
                            :placeholder="'Date *'"
                            v-model="milestoneCreate.date"
                            :default-date="
                              milestoneCreate.date
                            "
                            mandatory: true
                        ></DatePicker>
                        </td>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >Reminder Date </label
                          >
                          <DatePicker
                            :dataLoading="dataLoading"
                            solo
                            :default-date="
                              milestoneCreate.reminder_date
                            "
                            :placeholder="'Reminder Date '"
                            v-model="milestoneCreate.reminder_date"
                        ></DatePicker>
                        </td>
                      </tr>
                      
                      <tr>
                        <td class="py-2" valign = "top">
                          <label class="font-weight-500 font-size-16"
                            >Amount Type </label
                          >
                          <v-autocomplete
                            dense
                            color="cyan"
                            filled
                            :items="amountTypeList"
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            v-model.trim="milestoneCreate.amount_type"
                            label="Amount Type"
                            solo
                            flat
                            item-color="cyan"
                            item-text="text"
                            item-value="value"
                            hide-details
                            :rules="[
                              validateRules.required(
                                milestoneCreate.amount_type,
                                'Amount Type'
                              ),
                            ]"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Amount Type Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </td>
                        <td  class="py-2">
                          <template v-if = "milestoneCreate.amount_type == 1">
                            <label class="font-weight-500 font-size-16 required"
                              >Milestone Amount</label
                            >
                            <v-text-field
                              dense
                              color="cyan"
                              filled
                              :disabled="dataLoading"
                              :loading="dataLoading"
                              prepend-inner-icon="mdi-currency-usd"
                              hide-spin-buttons
                              v-on:keyup="changeMilestoneAmount"
                              type= "number"
                              v-on:keypress="limitDecimal($event, milestoneCreate.amount)"
                              v-model="milestoneCreate.amount"
                                v-model.trim.number.lazy="milestoneCreate.amount"
                              label="Milestone Amount"
                              :rules="[
                                 validateRules.required(
                                    milestoneCreate.amount,
                                    'Milestone Amount'
                                ),
                                validateRules.minLength(
                                  milestoneCreate.amount,
                                  'Milestone Amount',
                                  1
                                ),
                                validateRules.maxLength(
                                  milestoneCreate.amount,
                                  'Milestone Amount',
                                  12
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </template>
                          <template v-if = "milestoneCreate.amount_type == 2">
                            <label class="font-weight-500 font-size-16 required"
                              >Milestone Percentage</label
                            >
                            <v-text-field
                              dense
                              color="cyan"
                              filled
                              :disabled="dataLoading"
                              :loading="dataLoading"
                              append-icon="mdi-percent"
                              type= "number"
                              @keypress="percentageKeyup"
														  @keyup="checkPercentage"
                              v-model="milestoneCreate.milestone_percentage"
                                v-model.trim.number.lazy="milestoneCreate.milestone_percentage"
                              label="Milestone Percentage"
                              :rules="[
                                validateRules.required(
                                    milestoneCreate.milestone_percentage,
                                    'Milestone Percentage'
                                ),
                                validateRules.minLength(
                                  milestoneCreate.milestone_percentage,
                                  'Milestone Percentage',
                                  1
                                ),
                                validateRules.maxLength(
                                  milestoneCreate.milestone_percentage,
                                  'Milestone Percentage',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </template>
                            <p class = "text-black mt-1 pl-1 mb-1" v-if="milestoneCreate.amount_type ==2">
                            <b>Milestone Amount :
                              <span v-if="calculatedMilestoneAmountByPercentage">
                                {{ formatMoney(calculatedMilestoneAmountByPercentage) }}</span
																>
														
                              <!-- {{ detail.project_value }} --></b>
                          </p>
                          <p class = "text-black mt-1 pl-1 mb-1">
                            <b>Project Balance :
                              <span v-if="milestoneCreate.amount_type == 1">
																	{{ formatMoney(projectBalance - milestoneCreate.amount) }}</span
																>
																<span v-else>
																	{{ formatMoney(projectBalance - calculatedMilestoneAmountByPercentage) }}</span
																>
                              <!-- {{ detail.project_value }} --></b>
                          </p>
                          <p class = "text-black pl-1 mb-0">
                            <b>Project Value : {{ formatMoney(detail.project_value) }}</b>
                          </p>
                          
                        </td>


                      </tr>
                      <tr>
                        <td colspan = "2" class="py-2">
                          <label class="font-weight-500 font-size-16"
                            > Attachments</label
                          > 
                         <!--  <FileTemplate
                          allowUpload
                          isMinDisplay
                          :attachments="milestoneCreate.attachments"
                          v-on:file:updated="updateFiles"
                        ></FileTemplate> -->
                          <FileTemplate
                            :attachments="detail.attachments"
                            v-on:file:updated="updateFiles"
                            allowUpload
                          ></FileTemplate>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>
          <template v-slot:action>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    v-on:click="milestoneDialog = false"
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || dataLoading"
                    :loading="dataLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="checkInvoice"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Dialog>
      </template>
    </ListingTable>
    <Invoice
      v-if="invoice_dialog"
      :reassign-schedule-dialog="invoice_dialog"
      v-on:close="invoice_dialog = false"
      :milestone-id="milestone_id"
      :project-id="detail.id"
      :updated-id="invoice_id"
      :milestone-data="milestoneParent"
      v-on:success="getAllMilestones"
    ></Invoice>
    <InvoiceView
      :invoice-view-dialog="invoice_view_dialog"
      v-on:close="invoice_view_dialog = false"
      :updated-id="invoice_id"
    ></InvoiceView>
    <DeleteDialog :delete-dialog="deleteDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Milestone <span class="font-weight-700" style="color: red;">{{ deleteMilestoneName }}</span
          > will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="dataLoading"
          :loading="dataLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="accept_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm"  lazy-validation>
            <v-container class="px-10" fluid style="text-align: center">
              <p class="font-weight-600 font-size-19">
                If you change milestone amount then all related invoice will be deleted 
                    Are you sure  you want to change it?  
              </p>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <template>
          <v-btn
             class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="createOrUpdate"
          >
            Okay
          </v-btn>
          <v-btn
            
            v-on:click="accept_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Cancel
          </v-btn>
        </template>
       </template>  
      </Dialog>
    <Dialog :commonDialog="confirmationDialog">
          <template v-slot:title> Confirmation </template>
          <template v-slot:body>
            <v-row>
              <v-col md="12" class="pt-0">
                <h2 class="font-weight-600 delete-text text-center">
                  Are you sure you want to Complete Milestone # <span style="color:red">{{
                    milestone_barcode
                  }}</span> 
                  ?
                </h2>
              </v-col>
            </v-row>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              :loading="dataLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="updateStatus(updateStatusValue,updateStatusUuid)"
            >
              Yes
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="confirmationDialog = false"
            >
              No
            </v-btn>
          </template>
        </Dialog>
  </v-container>
  <!--end::Ticket Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { /* QUERY, */DELETE,PATCH,GET, POST ,PUT} from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
/* import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib"; */
/* import TableActivity from "@/view/pages/partials/Table-Activity.vue"; */
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";  
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import DeleteDialog from "@/view/pages/partials/Delete";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import Invoice from "@/view/pages/partials/Milestone-Invoice.vue";
import InvoiceView from "@/view/pages/partials/Milestone-Invoice-view.vue";

import ValidationMixin from "@/core/plugins/validation-mixin";



moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "timesheet-internal-list",
  data() {
    return {
      updateStatusValue : null,
      updateStatusUuid : null,
      parent: 0,
      invoice_dialog:false,
      invoice_id:0,
      invoice_view_dialog:false,
      dates: [],
      deleteMilestoneName : null,
      dataRows: [],
      milestone_barcode : null,
      dataLoading: true,
      deleteUuid : null,
      projectBalance : 0,
      milestoneDialog: false,
      confirmationDialog : false,
      deleteDialog :  false,
      accept_dialog:false,
      singleMilestone:{},
      milestoneList : [],
      statusUpdateList:[
         { value: 1, text: "Complete", color: 'green' },
				{ value: 2, text: "Pending" ,color: 'red'},  
      ],
      amountTypeList: [
         { value: 1, text: "Dollar" },
				{ value: 2, text: "Percentage" },
    ],
      
      filter: {
        search: null,
      },
    /*   timeout: null,
      timeoutLimit: 500,
      attachments: [], */
      milestone_id:0,
      milestoneParent : {},
      milestoneCreate: {
        project: 0,
        name: null,
        description: null,
        date: null,
        uuid:null,
        reminder_date: null,
        amount_type: 2, 
        milestone_percentage:0,
        amount: 0,
        project_balace: null,
        attachments: []
      },

     
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllMilestones();
        }
       /*  this.setProjectBalance(); */
      },
    },
   
  },
  components: {
    Barcode,
    Dialog,
    DatePicker,
    FileTemplate,
    /* TableActivity, */
    DeleteDialog,
    ListingTable,
    Invoice,
    InvoiceView
  },
  methods: {
    checkInvoice(){
     
     if(this.singleMilestone && this.singleMilestone.uuid &&  this.milestoneCreate.amount !=  this.singleMilestone.amount && this.singleMilestone.invoices){
         this.accept_dialog = true;
      }else{
           this.createOrUpdate();
      }
    },
    deleteData(data){
      this.deleteMilestoneName = data.name
      this.deleteUuid = data.uuid; 
      this.deleteDialog = true;
    },
    invoiceDialog(parent,id) {
      this.invoice_id = 0;
      this.milestone_id = id;
      this.milestoneParent = parent;
      this.invoice_dialog = true;
    },
    invoiceUpdateDialog(parent,milestone,invoice) {
      this.milestone_id = milestone;
      this.milestoneParent = parent;
      this.invoice_id = invoice;
      this.invoice_dialog = true;
    },
    invoiceViewDialog(invoice) {
      this.invoice_id = invoice;
      this.invoice_view_dialog = true;
    },
    attchments(){
      let query = { ...this.$route.query };
        query.tab = 'attachment';
          this.$router.push({ query });
    },
    deleteConfirm() {
      this.dataLoading = true;
      this.$store
        .dispatch(DELETE, { url: `project-milestone/${this.deleteUuid}` })
        .then(() => {
          this.deleteUuid = null;
          this.deleteDialog = false;
          this.deleteMilestoneName = null;
          this.getAllMilestones();
          this.$emit('reloadData',true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    changeMilestoneAmount() {
      if(this.milestoneCreate?.amount < 0 || this.milestoneCreate?.amount > this.project_balace){
        this.milestoneCreate.amount = 0  
      }
			if (this.milestoneCreate?.amount > this.detail.project_value) {
				this.milestoneCreate.amount = this.detail.project_value;
			}
		},
    openConfirm(value,data){
       if(value == 1){
         this.milestone_barcode = data.barcode
         this.updateStatusValue = value;
         this.updateStatusUuid = data.uuid;
         this.confirmationDialog = true;
       }else{
        return false;
       }
        
    } ,
    updateStatus(value,uuid) {
      this.$store
        .dispatch(PATCH, {
          url: `project-milestone/status`,
          data: {
            status : value,
            uuid : uuid,
          },
        })
        .then(() => {
          this.confirmationDialog = false;
          this.getAllMilestones()
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    getTextColor(data) {
      if (data == 1) {
        return "green--text";
      }
      if (data == 2) {
        return "red--text";
      }
    },
    getColor(data) {
      if (data == 1) {
        return "green";
      }
      if (data == 2) {
        return "red";
      }
    },
    setProjectBalance() {
			if (this.milestoneCreate && this.milestoneCreate.uuid) {    
				this.projectBalance =
					Number(this.detail?.project_balance) + Number(this.milestoneCreate.amount);
			} else {
				this.projectBalance = Number(this.detail?.project_balance);
			}
		},
    createMilestone(){
      this.milestoneCreate = {
        project: 0,
        name: null,
        description: null,
       /*  date: null, */
        uuid:null,
     /*    reminder_date: null, */
        amount_type: 2, 
        milestone_percentage:0,
        amount: 0,
        project_balace: null,
        attachments: []
      } ,
      this.milestoneDialog = true
      this.setProjectBalance();
    },
    updateFiles(param) {
        this.milestoneCreate.attachments = param;
    },
  
    percentageKeyup($event) {
			if (String(this.milestoneCreate.milestone_percentage).includes(".")) {
				let percentage = String(this.milestoneCreate.milestone_percentage).split(
					"."
				);
				if (percentage[1].length > 1) {
					$event.preventDefault();
				}
			}
			if (
				this.projectBalance <
				Number(this.milestoneCreate.milestone_percentage / 100) * Number(this.detail?.project_value)
			) {
				this.milestoneCreate.milestone_percentage = 0;
			}
			const percentage = this.milestoneCreate.milestone_percentage + Number($event.key);
			if (percentage > 100) {
				$event.preventDefault();
			}
		},
		checkPercentage() {
			if (
				this.projectBalance <
				Number(this.milestoneCreate.milestone_percentage  / 100) * Number(this.detail?.project_value)
			) {
				this.milestoneCreate.milestone_percentage  = 0;
				  ErrorEventBus.$emit(
          "update:error",
          InitializeError("Percentage Can not be greater then remaining percentage.")
        );
        return false;
			}
		},
    createOrUpdate() {
      const _this = this;
      if (!_this.$refs.milestoneForm.validate()) {
        return false;
      }
        _this.milestoneCreate.amount = this.milestoneCreate.amount_type == 1 ? _this.milestoneCreate.amount : Number(_this.milestoneCreate.milestone_percentage), 
        _this.milestoneCreate.project = _this.detail.id
       let url  = '';
       let requst = '';
     
       if(_this.milestoneCreate.amount <= 0){
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Amonut Can not be less then zero.")
          );
          return false;
       }
      if(_this.milestoneCreate.uuid){
           url = `project-milestone/${_this.milestoneCreate.uuid}`
           requst = PUT
      }else{
         url = `project-milestone/`
          requst = POST
      } 
      _this.dataLoading = true;
      _this.$store
        .dispatch(requst, { 
          url: url,
          data: _this.milestoneCreate,
        })
        .then(() => {
          _this.accept_dialog = false;
          _this.$emit('reloadData',true)
          _this.getAllMilestones();
          _this.milestoneDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
  
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.milestoneCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 99) {
        let trimValue = finalval.substring(0, 100);
        this.milestoneCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.milestoneCreate.description &&
        this.milestoneCreate.description.length > 99
      ) {
        e.preventDefault();
      }
    },
    getAllMilestones() {     
      
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(GET, { url: `project-milestone/${this.detail.id}` })
        .then(({ data }) => {
          _this.milestoneList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    getSingle(uuid) {
      if(uuid){
        const _this = this;
      _this.milestoneDialog = true;
      _this.$store
        .dispatch(GET, { url: `project-milestone-single/${uuid}` })
        .then(({ data }) => {
          _this.singleMilestone = data;
          _this.singleMilestone.amount=  _this.lodash.toSafeInteger(_this.singleMilestone.amount);
          _this.singleMilestone.amount_type=  _this.lodash.toSafeInteger(_this.singleMilestone.amount_type);
          _this.milestoneCreate.uuid = _this.singleMilestone.uuid
          _this.milestoneCreate.name = _this.singleMilestone.name ? _this.singleMilestone.name : null,
          _this.milestoneCreate.description = _this.singleMilestone.description ? _this.singleMilestone.description : null,
          _this.milestoneCreate.date = _this.singleMilestone.date ? _this.singleMilestone.date : moment().format("YYYY-MM-DD"),
          _this.milestoneCreate.reminder_date = _this.singleMilestone.reminder_date ? _this.singleMilestone.reminder_date : moment().format("YYYY-MM-DD"),
          _this.milestoneCreate.amount = _this.singleMilestone.amount ? _this.lodash.toSafeInteger(_this.singleMilestone.amount) : null,
          _this.milestoneCreate.milestone_percentage = _this.singleMilestone.progress ? _this.singleMilestone.progress : null,
          _this.milestoneCreate.amount_type = _this.singleMilestone.amount_type ? this.lodash.toSafeInteger(_this.singleMilestone.amount_type) : 1;
          _this.setProjectBalance();
         /*  this.$emit('reloadData',true); */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
      }
    },
   
  },
  mounted() {
  /*  this.$emit('reloadData',true); */
  },
  computed: {
		/* ...mapGetters(["errors"]), */
		calculatedMilestoneAmount() {
			if (this.milestoneCreate.milestone_percentage  > 0) {
				const amount = this.detail?.project_balance / this.milestoneCreate.milestone_percentage ;
				let a = amount * 100;
				return a.toFixed(2);
			} else {
				return 0;
			}
		},
    calculatedMilestoneAmountByPercentage() {
			return Number(this.milestoneCreate.milestone_percentage / 100) * Number(this.detail && this.detail.project_value);
		},
	},
};
</script>
